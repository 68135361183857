import React from "react";
import { Helmet } from "react-helmet";
import values from "../assets/images/values.png";
import { Link } from "react-router-dom";

function FullStackDeveloper() {
  return (
    <div className="leading-relaxed">
      <Helmet>
        <title>Développeur Full Stack NodeJS | Tech' Talents</title>
        <meta
          name="description"
          content="Rejoignez notre équipe en tant que Développeur(se) Full Stack NodeJS chez Tech' Talents. Découvrez comment vous pouvez contribuer à des projets digitaux innovants et socialement responsables."
        />
        <meta
          property="og:title"
          content="Développeur Full Stack NodeJS | Tech' Talents"
        />
        <meta
          property="og:description"
          content="Participez à des projets innovants en tant que Développeur(se) Full Stack NodeJS chez Tech' Talents. Explorez des opportunités de carrière enrichissantes et engagez-vous dans des initiatives de responsabilité sociale."
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="flex items-center justify-center flex-col pt-10 bg-secondaryColor">
        <h1 className="xs:text-lg lg:text-2xl text-center p-2 text-white font-extrabold uppercase">
          Développeur(se) Full Stack NodesJS
        </h1>
        <img src={values} />
      </div>
      <div className="m-10 ">
        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">
            À propos de l’offre d’emploi
          </h2>
          <p className="text-base">
            Tech’ Talents, c’est un peu l’OVNI dans la sphère des ESN. Et tu
            sais quoi, on l’assume à 100% ! ✨ Entreprise à mission, notre
            leitmotiv est de mettre l’humain et l’environnement au cœur de notre
            raison d’être 🌱 En tant que serial entrepreneurs, nous avons créé
            l’Agence en 2021, et notre vocation est de réaliser uniquement des
            projets porteurs de sens en mettant les femmes et les hommes au
            centre de nos métiers. Nos équipes accompagnent les clients et leur
            offrent une interaction hors normes en adéquation avec notre
            “socialement responsable”.
          </p>
        </div>
        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">
            Nos métiers
          </h2>
          <ul className="list-disc list-inside text-base">
            <li>Réalisation de projets digitaux</li>
            <li>Intégration de Salesforce (CRM)</li>
            <li>Mise à disposition de ressources techniques (régie)</li>
          </ul>
        </div>
        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">
            Ce que l’on te propose
          </h2>
          <p className="text-base">
            En tant Tech Lead ou ayant réussi dans une expérience similaire, tu
            es doté d'excellentes compétences techniques et tu désires
            t’investir dans des projets RSE ? Alors, rejoins l’aventure Tech’
            Talents 🏁 ⚡
          </p>
        </div>
        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">
            En détails ça donne quoi ?
          </h2>
          <p className="text-base">
            Rattaché directement à la direction de Tech’ Talents, véritable
            référent et garant technique auprès des équipes ou chez nos clients,
            tu occupes, avec détermination et passion, un poste essentiel pour
            la réussite et la qualité des projets.
          </p>
        </div>
        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">
            Tes missions
          </h2>
          <ul className="list-disc list-inside text-base">
            <li>
              Leadership technique : guider une équipe de développement dans la
              conception, le développement et la mise en œuvre de solutions
              technologiques de pointe.
            </li>
            <li>
              Expertise technique : apporter une expertise approfondie dans le
              développement de logiciels, les architectures système, et les
              meilleures pratiques de codage.
            </li>
            <li>
              Support technique : répondre aux questions des développeurs,
              solutionner les problèmes, debugger ou accompagner dans le
              développement des solutions.
            </li>
            <li>
              Gestion de projet : superviser le cycle de vie complet des
              projets, de la conception à la livraison, en veillant à la qualité
              et à la conformité.
            </li>
            <li>
              Communication et collaboration : travailler en étroite proximité
              avec les équipes clients et les développeurs afin de garantir
              l'alignement stratégique des projets. Être moteur dans
              l'utilisation des outils de collaboration.
            </li>
            <li>
              Revue de code : mener des revues de code régulières pour garantir
              la qualité, la sécurité et la cohérence des développements.
            </li>
            <li>
              Gestion & amélioration de processus : contribuer à la création et
              à l’instauration de processus efficaces pour optimiser le cycle de
              vie du développement.
            </li>
          </ul>
        </div>

        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">
            Ton profil
          </h2>
          <p className="text-base p-4">
            Diplômé.e d’une formation informatique (école d’ingénieur ou master
            universitaire), tu es depuis longtemps passionné par le
            développement que tu pratiques au quotidien dans ton métier depuis 4
            ou 5 ans. HTML/CSS, Javascript, NodeJS et ReactJS n’ont pas de
            secret pour toi mais tes domaines d’expertise ne s’arrêtent pas là :{" "}
          </p>
          <ul className="list-disc list-inside text-base">
            <li>Autres Langages | Typescript, PHP, .NET</li>
            <li>
              Framework/Librairie | Une première expérience sur NestJS serait
              appréciée. VueJS
            </li>
            <li>BDD | MySQL, PostgreSQL, Oracle, mongoDB</li>
            <li>Architecture | CI/CD, Docker, AWS</li>
            <li>Versioning | GIT</li>
            <li>
              Gestion de tâche, communication | Jira, Asana, ClickUp, Slack,
              Trello{" "}
            </li>
            <li>Méthode agile | Kanban, Scrum</li>
            <li>Langues | Français & Anglais courant</li>
          </ul>
        </div>
        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">Contact</h2>
          <p className="text-base">
            3 rue Princesse 59800 LILLE – 12 rue de Montyon 75009 PARIS
            <br />
            Téléphone : 06 26 36 76 89
            <br />
            Email :{" "}
            <Link
              to="mailto:contact@tech-talents.fr"
              className="text-blue-500 hover:underline"
            >
              contact@tech-talents.fr
            </Link>
          </p>
          <button>
            <Link
              to="/contactez-nous"
              className="inline-flex text-base text-center items-center px-3 py-2 font-medium shadow-xl text-primaryColor bg-textColor hover:bg-primaryColor hover:text-textColor mt-5 mr-1 border hover:border hover:border-textColor rounded-md cursor-pointer mx-auto"
            >
              Je postule{" "}
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default FullStackDeveloper;
