import React from "react";
import Coffee from "../assets/images/sharing-coffee.webp";
import Projects from "../assets/images/expertise-it.webp";
import Parité from "../assets/images/parité.webp";
import { Helmet } from "react-helmet";

function NosValeurs() {
  return (
    <div>
      <Helmet>
        <title>Nos Valeurs - Tech Talents</title>
        <meta
          name="description"
          content="Découvrez les valeurs qui animent Tech Talents : l'humain au cœur de l'aventure, le sens donné à chaque projet, et notre engagement pour la parité homme/femme dans le secteur de la tech."
        />
      </Helmet>
      <div className="flex items-center justify-center relative text-center py-10 xs:h-[15rem] lg:h-[30rem] xl:h-[30rem] bg-valeurs bg-cover bg-bottom bg-black/40">
        <h2 className="p-2 rounded-md z-10 relative xs:text-lg text-lg text-primaryColor font-extrabold uppercase bg-textColor">
          Nos valeurs
        </h2>
      </div>

      <div className="relative xs:px-4 lg:px-36 grid xs:grid-cols-1 lg:grid-cols-2 h-auto bg-textColor py-4 gap-8">
        <div className="flex flex-col justify-center py-8 md:mx-4">
          <h3 className="text-lg uppercase text-primaryColor font-thin xs:text-center lg:text-right">
            Humainf
          </h3>
          <h2 className="xs:text-md lg:text-lg uppercase text-primaryColor font-black mb-4 xs:text-center lg:text-right">
            Au cœur de <br /> l’aventure humaine
          </h2>
          <p className="xs:text-base lg:text-md text-primaryColor xs:text-center lg:text-right">
            Dans notre secteur d’activité, l’humain n’est souvent considéré que
            comme une ressource. Il a souvent le sentiment qu’il est un numéro
            ou un pion, que l’on déplace au gré des missions, sans vraiment
            faire partie d’une entreprise. Chez Tech’ Talents, les femmes et les
            hommes sont le cœur, la raison d’être LEUR ESN. Ils font partie
            intégrante d’une aventure humaine largement partagée, parce qu’ils
            en sont les parties prenantes, les acteurs.
          </p>
        </div>
        <div className=" flex xs:justify-center lg:justify-center items-center md:mx-8">
          <img
            src={Coffee}
            className="xs:h-auto xs:w-full md:h-2/3 lg:w-auto lg:h-3/4 object-cover shadow-boxes-white "
          />
        </div>
      </div>

      {/* 2 */}

      <div className="relative xs:px-4 lg:px-36 grid xs:grid-cols-1 lg:grid-cols-2 h-auto py-10 xs:mb-20 gap-8 ">
        <div className="xs:order-2 lg:order-1 flex xs:justify-center lg:justify-center items-center py-8 md:mx-8">
          <img
            src={Projects}
            className="xs:h-auto xs:w-full md:h-2/3 lg:w-auto lg:h-3/4 object-cover shadow-boxes-blue"
          />
        </div>
        <div className="flex flex-col justify-center xs:order-1 lg:order-2 md:mx-4">
          <h3 className="text-lg uppercase text-textColor font-thin xs:text-center lg:text-left">
            SENS
          </h3>
          <h2 className="text-lg uppercase text-textColor font-black mb-4 xs:text-center lg:text-left">
            Donner du sens <br /> à chaque projet
          </h2>
          <p className="xs:text-base lg:text-md text-textColor xs:text-center lg:text-left">
            La mobilisation et l’engagement du management sur ce credo est
            tangible du fait de ses actions en faveur des enjeux actuels
            sociaux, sociétaux et environnementaux. Tech’ Talents, l’entreprise
            de services numérique socialement responsable, confère à l’ensemble
            de ses parties prenantes un fort sentiment d’appartenance à une
            communauté d’entreprises et de dirigeants résolument déterminés à
            préparer un monde meilleur. Nos valeurs attirent naturellement des
            expertes et experts en quête de sens et des entreprises qui
            souhaitent s’engager.
          </p>
        </div>
      </div>
      {/* 3 */}
      <div className="relative xs:px-4 lg:px-20 grid xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 bg-textColor gap-8 py-4">
        <div className="flex flex-col justify-center md:mx-4">
          <h3 className="text-lg uppercase text-primaryColor font-thin xs:text-center lg:text-right">
            parité
          </h3>
          <h2 className="xs:text-lg uppercase text-primaryColor font-black mb-4 xs:text-center lg:text-right">
            La parité <br /> homme / femme
          </h2>
          <p className="xs:text-base lg:text-md text-primaryColor xs:text-center lg:text-right">
            La parité homme / femme est le leitmotiv de la société actuelle.
            Mais concrètement, on en est loin, a fortiori dans le secteur de la
            tech !  Chez Tech Talents, c’est maintenant ! C’est bien sûr un des
            piliers de notre credo « socialement responsable » : toute femme
            chez Tech Talents a les mêmes droits, les mêmes opportunités, les
            mêmes accès, les mêmes occasions de choisir et le même salaire que
            son homologue masculin.
          </p>
        </div>
        <div className="flex xs:justify-center lg:justify-center items-center md:mx-8">
          <img
            src={Parité}
            className="xs:h-auto xs:w-full md:h-3/4 lg:w-auto lg:h-3/4 object-cover shadow-boxes-white"
          />
        </div>
      </div>
    </div>
  );
}

export default NosValeurs;
