import React from "react";
import { Link } from "react-router-dom";

const SalesforceCards = ({ card }) => {
  return (
    <div className="flex xs:justify-center xs:items-center xs:text-center xs:flex-col lg:flex-row items-center bg-textColor rounded-lg shadow lg:max-w-3xl mx-auto my-4 lg:p-4">
      <img
        className="xs:w-auto md:w-auto rounded-t-lg md:rounded-none md:rounded-l-lg xs:h-56 md:h-64 shadow-lg"
        src={card.imageSrc}
        alt={card.alt}
      />
      <div className="flex flex-col justify-between leading-normal">
        <h2 className="text-lg font-bold text-primaryColor p-3">
          {card.title}
        </h2>

        <p className="text-primaryColor text-base p-3">{card.text}</p>

        {card.rating && (
          <div className="flex justify-center p-2">
            <div className="flex items-center gap-2">
              <p className="text-sm text-white">Avis :</p>
              <div className="text-yellow-400 text-base">
                {"★".repeat(card.rating)}
                {"☆".repeat(5 - card.rating)}
              </div>
            </div>
          </div>
        )}

        {/* Affichage du nombre d'entreprises formées */}
        {card.companiesTrained && (
          <p className="text-sm text-primaryColor italic p-2">
            {card.companiesTrained} entreprises formées à ce jour
          </p>
        )}

        <div className="flex justify-center gap-4 mt-4">
          {card.pdfLink && (
            <a
              href={card.pdfLink}
              download
              className="px-4 py-2 bg-green-500 text-white rounded text-base text-center"
            >
              📥 Aperçu de la formation
            </a>
          )}

          <Link to="../contactez-nous">
            <button className="px-4 py-2 bg-blue-500 text-primaryColor rounded text-base">
              En savoir plus
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SalesforceCards;
