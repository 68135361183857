import { React } from "react";
import { Helmet } from "react-helmet";
import "../App.css";
import Cards from "../components/Homepage/Cards/cards";
import { ScrollableTabsButtonPrevent } from "../components/NotreExpertise/SwiperExpertise";

function NotreExpertise() {
  return (
    <div>
      <div className="xs:h-[15rem] lg:h-[30rem] bg-expertise bg-center bg-cover mb-12 ">
        <div className="flex xs:h-[15rem] lg:h-[30rem] justify-center items-center bg-black/40">
          <h1 className="uppercase xs:text-lg lg:text-2xl xl:text-3xl text-center text-white font-bold px-8">
            Nos expertises informatiques
          </h1>
        </div>
      </div>
      <div className="flex flex-col gap-8 items-center pt-2 py-8">
        <Helmet>
          <title>
            Nos Expertises - Découvrez les spécialisations informatiques de
            TechTalent
          </title>
          <meta
            name="description"
            content="Plongez dans l'histoire de Tech' Talents, notre mission, nos valeurs et notre engagement envers une technologie plus humaine et responsable. Découvrez comment nous transformons le paysage technologique avec passion."
          />
        </Helmet>
        <Cards />
      </div>
      <div className="h-auto bg-textColor xs:m-4 lg:m-20 rounded-lg ">
        <h2 className="xs:text-md lg:text-xl text-primaryColor text-center uppercase font-extrabold py-4 mt-12">
          Nos clients en hospitality & entertainement
        </h2>
        <ScrollableTabsButtonPrevent />
      </div>
    </div>
  );
}

export default NotreExpertise;
