import React from "react";
import { Helmet } from "react-helmet";
import values from "../assets/images/values.png";
import { Link } from "react-router-dom";

function ChefProjets() {
  return (
    <div className="leading-relaxed">
      <Helmet>
        <title>Chef de projets en alternance | Tech' Talents</title>
        <meta
          name="description"
          content="Rejoignez Tech' Talents en tant que chef(fe) de projets en alternance. Coordonnez des projets digitaux significatifs et engagez-vous dans des initiatives RSE. Découvrez plus sur nos missions et comment postuler."
        />
        <meta
          property="og:title"
          content="Chef de projets en alternance | Tech' Talents"
        />
        <meta
          property="og:description"
          content="Rejoignez Tech' Talents en tant que chef(fe) de projets en alternance. Coordonnez des projets digitaux significatifs et engagez-vous dans des initiatives RSE. Découvrez plus sur nos missions et comment postuler."
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="flex items-center justify-center flex-col pt-10 bg-secondaryColor">
        <h1 className="xs:text-lg lg:text-2xl text-center p-2 text-white font-extrabold uppercase">
          Chef(ffe) de projets en alternance
        </h1>
        <img src={values} />
      </div>
      <div className="m-10 ">
        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">
            À propos de Tech’ Talents
          </h2>
          <p className="text-base">
            Tech’ Talents, c’est un peu l’OVNI dans la sphère des ESN. Et tu
            sais quoi, on l’assume à 100% ! ✨ Entreprise à mission, notre
            leitmotiv est de mettre l’humain et l’environnement au cœur de notre
            raison d’être 🌱 Notre vocation est de réaliser uniquement des
            projets porteurs de sens en mettant les femmes et les hommes au
            centre de nos métiers.
          </p>
        </div>
        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">
            Nos métiers
          </h2>
          <ul className="list-disc list-inside text-base">
            <li>Réalisation de projets digitaux</li>
            <li>Intégration de Salesforce</li>
            <li>Mise à disposition de ressources techniques (régie)</li>
          </ul>
        </div>
        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">
            Ce que l’on te propose
          </h2>
          <p className="text-base">
            Tu as une forte appétence pour le travail en équipe, le suivi de
            projet, la coordination des acteurs d’un projet et tu aimes voir
            l’impact de ton travail ? A même de répondre à divers enjeux
            clients, tu es également désireux de t’investir dans des projets RSE
            ? Alors, rejoins l’aventure Tech’ Talents 🏁
          </p>
        </div>
        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">
            En détails ça donne quoi ?
          </h2>
          <p className="text-base">
            Sous la responsabilité de la Direction, tu intègres notre centre de
            services lillois pour coordonner et gérer, au sein de nos équipes,
            les projets auxquels tu apportes tes connaissances et ta motivation.
            Tu participes à toutes les phases d’un projet, de la conception aux
            développements, en passant par les phases de recette, jusqu’à la
            mise en production et son suivi.
          </p>
        </div>
        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">
            Tes missions
          </h2>
          <ul className="list-disc list-inside text-base">
            <li>Créer et animer la backlog en collaboration avec le client</li>
            <li>
              Mettre en place les structures du projet et ses règles de
              fonctionnement (méthodes, outils de pilotage, indicateurs…)
            </li>
            <li>
              Définir avec les équipes du projet, les objectifs et les délais de
              réalisation des livrables (applications, modules, développements
              spécifiques...)
            </li>
          </ul>
        </div>
        <div className="mb-10 text-textColor">
          <h2 className="xs:text-lg lg:text-2xl font-semibold mb-4">Contact</h2>
          <p className="text-base">
            3 rue Princesse 59800 LILLE – 12 rue de Montyon 75009 PARIS
            <br />
            Téléphone : 06 26 36 76 89
            <br />
            Email :{" "}
            <Link
              to="mailto:contact@tech-talents.fr"
              className="text-blue-500 hover:underline"
            >
              contact@tech-talents.fr
            </Link>
          </p>
          <button>
            <Link
              to="/contactez-nous"
              className="inline-flex text-base text-center items-center px-3 py-2 font-medium shadow-xl text-primaryColor bg-textColor hover:bg-primaryColor hover:text-textColor mt-5 mr-1 border hover:border hover:border-textColor rounded-md cursor-pointer mx-auto"
            >
              Je postule
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChefProjets;
