import React from "react";
import { Helmet } from "react-helmet";
import Timeline from "../components/Histoire/timeline";
import Responsive from "../components/Histoire/PartnerLogosSlider";
import Mansory from "../components/Histoire/Mansory";
// Images

function NotreHistoire() {
  return (
    <div className="">
      {/* SEO */}
      <Helmet>
        <title>
          Notre Histoire - Tech' Talents : Découvrez nos Origines et Valeurs
        </title>
        <meta
          name="description"
          content="Plongez dans l'histoire de Tech' Talents, notre mission, nos valeurs et notre engagement envers une technologie plus humaine et responsable. Découvrez comment nous transformons le paysage technologique avec passion."
        />
      </Helmet>
      <div className="bg-nature bg-center bg-cover h-auto xs:py-4">
        <div className="absolute inset-0 bg-black bg-opacity-30 h-full ]"></div>
        <div className="relative flex flex-col justify-center items-center lg:h-[90vh]">
          <h1 className="text-white xs:text-lg lg:text-2xl my-4 uppercase font-extrabold ">
            Notre Histoire
          </h1>
          <p className="text-white xs:text-sm lg:text-md xs:w-[90%] lg:w-[70%] text-justify">
            Tech’ Talents est née en 2021 d’un constat : dans un monde où le
            digital devient prépondérant dans nos organisations, et ce quel que
            soit le secteur, la délégation d’expertise est gérée autour des
            projets en tant que tels, mais rarement autour des femmes et des
            hommes qui y contribuent. Les deux fondateurs de Tech’ Talents,
            amis, passionnés, serial entrepreneurs, ont souhaité ainsi créer une
            entreprise de services numérique (ESN) indépendante, dont la mission
            est de réaliser uniquement des projets qui sont porteurs de sens, et
            surtout une entreprise qui remet les femmes et les hommes au cœur de
            nos métiers, afin de transformer tout besoin professionnel en une
            véritable aventure humaine. Nous offrons à nos clients partenaires
            la possibilité de partager les valeurs humaines de Tech’ Talents
            grâce à nos équipes internes dont le credo est d’être à votre
            écoute, offrir une interaction hors normes, une adhésion au projet
            confié en adéquation avec notre « socialement responsable ». Tech’
            Talents une entreprise paritaire, solidaire et engagée.
          </p>
        </div>
      </div>

      <div>
        <Timeline />
      </div>

      <div className="bg-textColor">
        <Responsive />
      </div>

      <div className="">
        <h2 className="text-primaryColor bg-textColor text-xl  uppercase font-black text-center py-10">
          Nos locaux
        </h2>
        <Mansory />
      </div>
    </div>
  );
}

export default NotreHistoire;
