import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
import Partenaire from "../../assets/images/Timeline/partenaire.jpg";
import Recrutement from "../../assets/images/Timeline/recrutement.webp";
import Teambuildings from "../../assets/images/Timeline/Teambuilding.png";
import Inauguration from "../../assets/images/Timeline/inauguration.jpg";
import Salesforcetour from "../../assets/images/Timeline/Salesforcetour.png";
import Mission from "../../assets/images/Timeline/mission.png";
import Alternants from "../../assets/images/Timeline/alternant.jpg";

const Timeline = () => {
  return (
    <div className="bg-transparent ">
      <HorizontalScrollCarousel />
    </div>
  );
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["0.2%", "-83%"]);

  return (
    <section ref={targetRef} className="relative h-[200vh]">
      <div className="sticky top-0 flex h-screen w-full items-center overflow-hidden">
        <motion.div style={{ x }} className="flex">
          {cards.map((card) => {
            return <Card card={card} key={card.id} />;
          })}
        </motion.div>
      </div>
    </section>
  );
};

const Card = ({ card }) => {
  return (
    <div
      key={card.id}
      className="group relative h-[80vh] w-[80vw] overflow-hidden"
    >
      <div
        style={{
          backgroundImage: `url(${card.url})`,
          backgroundSize: "cover",
          backgroundPosition: card.position,
          height: "100vh",
          width: "100%",
        }}
        className="absolute inset-0 z-0 transition-transform duration-300"
        role="img"
        aria-label={card.alt}
      ></div>
      <div className="absolute inset-0 z-10 grid place-content-center bg-black/25">
        <h2 className="px-8 py-2 text-3xl font-black uppercase text-white xs:text-lg lg:text-xl">
          {card.title}
        </h2>
        <p className="px-8 py-4 text-xl font-black uppercase text-white xs:text-lg lg:text-xl">
          {card.text}
        </p>
      </div>
    </div>
  );
};

export default Timeline;

const cards = [
  {
    url: "https://images.pexels.com/photos/356056/pexels-photo-356056.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=18",
    title: "Création de Tech' Talents",
    text: "2021",
    id: 1,
    position: "center",
    alt: "Lancement de Tech' Talents, ESN spécialisée en Salesforce pour le sport et l'événementiel",
  },
  {
    url: Partenaire,
    title: "Tech’ Talents devient partenaire salesforce",
    text: "2021",
    id: 2,
    position: "center",
    alt: "Partenariat de Tech' Talents avec Salesforce, intégrateur système dans le sport",
  },
  {
    url: Recrutement,
    title: "Recrutement & mise en place des équipes",
    text: "2022",
    id: 3,
    position: "center",
    alt: "Recrutement chez Tech' Talents, équipes IT spécialisées en Salesforce et API",
  },
  {
    url: Teambuildings,
    title: "Teambuildings",
    text: "2022",
    id: 4,
    position: "center",
    alt: "Teambuilding chez Tech' Talents, renforcement d'équipe pour projets IT en sport",
  },
  {
    url: Inauguration,
    title: "Inauguration des bureaux",
    text: "2023",
    id: 5,
    position: "center",
    alt: "Inauguration des nouveaux bureaux de Tech' Talents, espace de travail pour innovation IT",
  },
  {
    url: Alternants,
    title: "Premiers alternants",
    text: "2023",
    id: 6,
    position: "top",
    alt: "Premiers alternants chez Tech' Talents, formation Salesforce, développement Node.js et Communication ",
  },
  {
    url: Mission,
    title: "Tech’ Talents devient entreprise à mission",
    text: "2023",
    id: 7,
    position: "center",
    alt: "Tech' Talents devient une entreprise à mission RSE, engagement en technologies responsables",
  },
  {
    url: Salesforcetour,
    title: "Tech’ Talents participe au SF World Tour",
    text: "2024",
    id: 8,
    position: "center",
    alt: "Participation de Tech' Talents au Salesforce World Tour, présentation de solutions cloud et API",
  },
];
